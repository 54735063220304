/* eslint-disable */
import { createWebHistory, createRouter } from "vue-router";
import store from '@/store/index.js'


const routes = [
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/views/LoginPage.vue"),
  /*  meta: {
      requiresAuth: true,
    },*/
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/help",
    name: "help",
    component: () => import("@/views/HowItWork.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/FaqView.vue"),

  },
  {
    path: "/remind",
    name: "remind",
    component: () => import("@/views/RemindPass.vue"),
  /*  meta: {
      requiresAuth: true,
    },*/
  },
   {
    path: "/connect/google/redirect",
    name: "redirect",
    component: () => import("@/views/RedirectView.vue"),
  /*  meta: {
      requiresAuth: true,
    },*/
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterForm.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("@/views/RegisterDemoForm.vue"),
  /*  meta: {
      requiresAuth: true,
    },*/
  },
  {
    path: "/scripts",
    name: "myScripts",
    component: () => import("@/views/MyScripts.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/views/MyAccount.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/accounts",
    name: "accounts",
    component: () => import("@/views/MyAccount.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  
 {
    path: "/dialog/:id",
    name: "dialog",
    component: () => import("@/views/DialogEdit.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payconfirm",
    name: "payconfirm",
    component: () => import("@/views/PayConfirm.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paycancel",
    name: "paycancel",
    component: () => import("@/views/PayCancel.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: {}
  },{
    path: "/:catchAll(.*)",
    redirect: "/"
  }


  //{ path: '/:pathMatch(.*)',  component: () => import("@/views/DialogEdit.vue"), }
  /*{
    path: "/:catchAll(.*)",
    beforeEnter: (to, from, next) => {
      let link =
        process.env.NODE_ENV === "production" ?
        to.fullPath :
        process.env.VUE_APP_WT_HOST + to.fullPath;
      let target = "_blank";

     

      next(false);
      //    abc.replace(/([^:]\/)\/+/g, "$1");
    },
  }*/


]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})

export default router;