<template>
 
   <v-dialog v-model="show"
   
    max-width="600px"
    :close-on-click="false"
    hide-overlay
    persistent
    :close-on-content-click="false"
  >
     <div style="height:100%" class="d-flex flex-column justify-center align-center"> <v-card width="400" class="elevation-6 mb-2 rounded-xl  pa-3  justify-around">
         <div style="font-size: 1.5rem ;" class="mt-12 s-15 text-center ">{{getLangConst('newPassword')}}</div>
         <v-card-text class="mt-2"> 
          <v-form
          
         >
         <span>{{getLangConst('NewPassMessage')}} </span>
             <v-text-field 
             color="primary"
              density="comfortable"
             class="mt-5"
                prepend-inner-icon="mdi-lock" 
                v-model="password" 
                @blur="checkPass"
                @keyup="checkPass"
                :error-messages="passMsg"
                 variant="outlined"
                 :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="show1 = !show1"
                 :type="show1 ? 'text' : 'password'"
                  
                :label="getLangConst('password')"
               
                 >
           </v-text-field>
           
             <v-text-field 
             color="primary"
              class=""
              @blur="checkDoublePass()"
              @keyup="checkDoublePass()"
             density="comfortable"
                prepend-inner-icon="mdi-lock" 
                v-model="confirmPassword" 
                 :error-messages="pass2Msg"
                 variant="outlined"
                 
                 :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="show2 = !show2"
                 :type="show2 ? 'text' : 'password'"
                
                :label="getLangConst('RepeatPass')"
                
                 >
           </v-text-field>


          </v-form> 


            <v-btn block size="x-large" color="primary" :loading="loalBtn" :disabled="password==''||confirmPassword==''||passMsg!=''||pass2Msg!=''"  class="mt-5"  @click="confirmPass()"  >{{getLangConst('send')}}</v-btn> 

           
           </v-card-text>

     </v-card> 
    </div>
 </v-dialog>
</template>
<script>

import axios from 'axios';
  export default {
    data(){
      return {
        show:false,
        passMsg:'',
        pass2Msg:'',
        show1:false,
        show2:false,
        password : "",
        confirmPassword : "",
        loalBtn:false

      }
    },
    props: {
     showModal: {
      type: Boolean,
      default: false,
    },
 },
 computed:{
  sUrl(){return this.$store.getters.apiURL},
  user(){return this.$store.getters.getUser},

 },
     methods: {


      checkPass(){ 
        let regularExpression =  /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,160}$/
          if (this.password&&this.password.match(regularExpression)==null){
            this.passMsg=this.getLangConst("badPass")
          }else{
            this.passMsg=""
          }
      },
      checkDoublePass(){ 
        this.pass2Msg=this.password!=this.confirmPassword?this.getLangConst("bad2Pass"):''
      },
      confirmPass(){
        let data={
          "s" : "123",
          "email" :this.user.email,
          "pass":this.password,
          "edit": true,

        }

         axios
      .post(this.sUrl+"/api/scripts-lists/newEditor", {'data':data})
      .then(() => {
       this.$store.commit('setConfirmPass')
         
         
          })
         
         this.password=''
        this.confirmPassword=''
      },
    },
     mounted() {

        this.show= this.showModal


        },
        watch: {
        showModal(val) {
            this.show = val
        }
        },

  }
</script>
<style>
 .v-checkbox-btn .v-label{
  font-weight: 500;
  opacity: 100;
 }
</style>