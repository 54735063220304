// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import '@/styles/main.css'
//import colors from 'vuetify/lib/util/colors'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  
  {
    display: {
      mobileBreakpoint: 'md',
    },
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: '#0b6ef9', // #E53935
            'primary-darken-1':'#21446E',
             // #FFCDD2
            
          }
        },
      },
    },
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
