<template>
  <v-dialog v-model="show"
   class="mt-12"
    persistent
    fullscreen
    :close-on-click="false"
    hide-overlay
    :close-on-content-click="false"
  >

     <v-card class="rounded-sm">
         
          <v-card-title  class="pa-0" >
 <v-toolbar
            dark
            style="max-height:50px;"
            max-height="130px"
             min-height="130px"
            color="primary"
          >
          
            <v-toolbar-title class="mb-3" >{{getLangConst('dictionaries')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
             class="mt-n3"
              icon
              dark
             @click="cancel()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
            <v-tabs
          
          v-model="tab"
          >
            
            <v-tab  >{{getLangConst('stopWords')}}</v-tab>
            <v-tab  >{{getLangConst('synonyms')}}  </v-tab>
         </v-tabs>

          </v-card-title>

        <v-card-text >
           <v-window v-model="tab">
            <v-window-item 
            >
            <v-row >
                     <v-col style="min-height:85vh;" >
               <v-card  height="100%" color="primary" variant="outlined">
                <v-card-title >{{getLangConst('global')}}</v-card-title >
                <v-card-text >
                  <v-list  >
                    <v-list-item  class="mt-1" style="background-color:#eee;z-index:2000;"  variant="elevated" @click="selectOrgSw(item)" v-for="(item,indx) in stopGlobalWords" :key="indx">
                    <div class="d-flex">
                  {{indx+1}}.      <span class="ml-2">{{item.attributes.words}}</span>


                    </div>
                    </v-list-item>
                  </v-list>

                </v-card-text>



              </v-card>
              </v-col>

              <v-col >
               <v-card height="100%" color="primary" variant="outlined">
                <v-card-title >{{getLangConst('byOrganization')}}</v-card-title >
                <v-card-text >
                  <v-list  >
                    <v-list-item  class="mt-1" style="background-color:#eee"  variant="elevated" @click="selectOrgSw(item)" v-for="(item,indx) in stopOrgWords" :key="indx">
                    <div class="d-flex">
                  {{indx+1}}.      <span style="width:80%" class="ml-2">{{item.attributes.words}}</span>
                  
                  <v-spacer></v-spacer>
                  <v-icon class="ml-2"  @click="deleteSW(item.id)" >mdi-close-circle-outline</v-icon>
                    </div>
                    </v-list-item>
                  </v-list>

                </v-card-text>
              <v-card-actions class="align-center">

                <v-combobox
                  v-model="orgSw"
                  
                  closable-chips
                class="ma-2"
                  density="compact"
                  color="primary"
                  hide-selected
                  hide-message
                  hide-no-data
                  :hint="getLangConst('addWord')"
                  :label="getLangConst('forbiddenWords')"
                  multiple 
                  
                  aria-multiline
                  variant="outlined"
                  persistent-hint
                  chips
                  clearable
                  @click:clear="orgSwId='new'"
                >

                </v-combobox>
                <v-btn @click="save('stopList','org')" class="mt-n2" :disabled="!orgSw.length" color="primary">{{getLangConst('save')}}</v-btn>
              </v-card-actions>
              </v-card>
              </v-col>
               <v-col>
               <v-card height="100%" color="primary" variant="outlined">
                <v-card-title >{{getLangConst('byScript')}}</v-card-title >
                <v-card-text >
                  <v-list  >
                    <v-list-item  class="mt-1" style="background-color:#eee;z-index:2000;"  variant="elevated" @click="selectScriptSw(item)" v-for="(item,indx) in stopScriptWords" :key="indx">
                    <div class="d-flex">
                  {{indx+1}}.      <span class="ml-2">{{item.attributes.words}}</span>
                  
                  <v-spacer></v-spacer>
                  <v-icon style="z-index:2000;" @click="deleteSW(item.id)" >mdi-close-circle-outline</v-icon>
                    </div>
                    </v-list-item>
                  </v-list>

                </v-card-text>
              <v-card-actions class="align-center">

                <v-combobox
                  v-model="scriptSw"
                  
                  closable-chips
                class="ma-2"
                  density="compact"
                  color="primary"
                  hide-selected
                  hide-message
                  hide-no-data
                  :hint="getLangConst('addWord')"
                  :label="getLangConst('forbiddenWords')"
                  multiple 
                  
                  aria-multiline
                  variant="outlined"
                  persistent-hint
                  chips
                  clearable
                  @click:clear="scriptSwId='new'"
                >

                </v-combobox>
                <v-btn @click="save('stopList','script')" class="mt-n2" :disabled="!scriptSw.length" color="primary">{{getLangConst('save')}}</v-btn>
              </v-card-actions>
              </v-card>
              </v-col>
            </v-row>
             
            </v-window-item>
             <v-window-item 
            >
            <v-row >
                     <v-col style="min-height:85vh;" >
               <v-card  height="100%" color="primary" variant="outlined">
                <v-card-title >{{getLangConst('global')}}</v-card-title >
                <v-card-text >
                  <v-list  >
                    <v-list-item  class="mt-1" style="background-color:#eee;z-index:2000;"  variant="elevated" @click="selectOrgVw(item)" v-for="(item,indx) in variableGlobalWords" :key="indx">
                    <div class="d-flex">
                  {{indx+1}}.      <span class="ml-2">{{item.attributes.words}}</span>


                    </div>
                    </v-list-item>
                  </v-list>

                </v-card-text>



              </v-card>
              </v-col>

              <v-col >
               <v-card height="100%" color="primary" variant="outlined">
                <v-card-title >{{getLangConst('byOrganization')}}</v-card-title >
                <v-card-text >
                  <v-list  >
                    <v-list-item  class="mt-1" style="background-color:#eee"  variant="elevated" @click="selectOrgVw(item)" v-for="(item,indx) in variableOrgWords" :key="indx">
                    <div class="d-flex">
                  {{indx+1}}.      <span style="width:80%" class="ml-2">{{item.attributes.words}}</span>
                  
                  <v-spacer></v-spacer>
                  <v-icon class="ml-2"  @click="deleteSW(item.id)" >mdi-close-circle-outline</v-icon>
                    </div>
                    </v-list-item>
                  </v-list>

                </v-card-text>
              <v-card-actions class="align-center">

                <v-combobox
                  v-model="orgVw"
                  
                  closable-chips
                class="ma-2"
                  density="compact"
                  color="primary"
                  hide-selected
                  hide-message
                  hide-no-data
                   :hint="getLangConst('addWord')"
                  :label="getLangConst('variableWords')"
                  multiple 
                  aria-multiline
                  variant="outlined"
                  persistent-hint
                  chips
                  clearable
                  @click:clear="orgVwId='new'"
                >

                </v-combobox>
                <v-btn @click="save('variableList','org')" class="mt-n2" :disabled="!orgVw.length" color="primary">{{getLangConst('save')}}</v-btn>
              </v-card-actions>
              </v-card>
              </v-col>
               <v-col>
               <v-card height="100%" color="primary" variant="outlined">
                <v-card-title >{{getLangConst('byScript')}}</v-card-title >
                <v-card-text >
                  <v-list  >
                    <v-list-item  class="mt-1" style="background-color:#eee;z-index:2000;"  variant="elevated" @click="selectScriptVw(item)" v-for="(item,indx) in variableScriptWords" :key="indx">
                    <div class="d-flex">
                  {{indx+1}}.      <span class="ml-2">{{item.attributes.words}}</span>
                  
                  <v-spacer></v-spacer>
                  <v-icon style="z-index:2000;" @click="deleteSW(item.id)" >mdi-close-circle-outline</v-icon>
                    </div>
                    </v-list-item>
                  </v-list>

                </v-card-text>
              <v-card-actions class="align-center">

                <v-combobox
                  v-model="scriptVw"
                  
                  closable-chips
                class="ma-2"
                  density="compact"
                  color="primary"
                  hide-selected
                  hide-message
                  hide-no-data
                  :hint="getLangConst('addWord')"
                  :label="getLangConst('variableWords')"
                  multiple 
                  aria-multiline
                  variant="outlined"
                  persistent-hint
                  chips
                  clearable
                  @click:clear="scriptVwId='new'"
                >

                </v-combobox>
                <v-btn @click="save('variableList','script')" class="mt-n2" :disabled="!scriptVw.length" color="primary">{{getLangConst('save')}}</v-btn>
              </v-card-actions>
              </v-card>
              </v-col>
            </v-row>
             
            </v-window-item>
          </v-window>




              
        </v-card-text> 

    </v-card>
    </v-dialog> 
</template>     
  <script>
export default {
    data: () => ({
        tab:0,
        
        show:false,
        orgSw:[],
        orgSwId:'new',
        scriptSw:[],
        scriptSwId:'new',
        orgVw:[],
        orgVwId:'new',
        scriptVw:[],
        scriptVwId:'new',

    }),
 props: {
     showModal: {
      type: Boolean,
      default: false,
    },
    located: {
      type: String,
      default: '',
    },
 },
 computed : {
  specialWords(){  return this.$store.getters.getSpecialWords},
  stopOrgWords(){return this.specialWords.filter(s=>{return s.attributes.type=='stopList'&&s.attributes.domain=='org'})},
  stopScriptWords(){return this.specialWords.filter(s=>{return s.attributes.type=='stopList'&&s.attributes.domain=='script'&&s.attributes.scriptID==this.scriptID})},
  stopGlobalWords(){return this.specialWords.filter(s=>{return s.attributes.type=='stopList'&&s.attributes.domain=='global'})},
  variableOrgWords(){return this.specialWords.filter(s=>{return s.attributes.type=='variableList'&&s.attributes.domain=='org'})},
  variableScriptWords(){return this.specialWords.filter(s=>{return s.attributes.type=='variableList'&&s.attributes.domain=='script'&&s.attributes.scriptID==this.scriptID})},
  variableGlobalWords(){return this.specialWords.filter(s=>{return s.attributes.type=='variableList'&&s.attributes.domain=='global'})},
    scriptID(){return this.$route.params.id?''+this.$route.params.id:null}
  

  },
 methods: {
  selectOrgSw(item){
    this.orgSw=item.attributes.words.split(';')
    this.orgSwId=item.id

  },
    selectScriptSw(item){
    this.scriptSw=item.attributes.words.split(';')
    this.scriptSw=item.id

  },
    selectOrgVw(item){
    this.orgVw=item.attributes.words.split(';')
    this.orgVwId=item.id

  },
    selectScriptVw(item){
    this.scriptVw=item.attributes.words.split(';')
    this.scriptVwId=item.id

  },
  
    cancel(){
      this.name=""
      this.desc="";
      this.scriptTypeId=1
       this.$emit('cancel') 
    },


   async  save(type,domain){
        if(type=='stopList'){
          let attrName=domain+'Sw'
          let attrId=domain+'SwId'

          let words=this[attrName].join(';')
        await   this.$store.dispatch('addSW',{id:this[attrId],type:type,domain:domain,words:words,scriptID:this.scriptID})
          this[attrId]=null
          this[attrName]=[]
        }else{
           let attrName=domain+'Vw'
          let attrId=domain+'VwId'

          let words=this[attrName].join(';')
        await   this.$store.dispatch('addSW',{id:this[attrId],type:type,domain:domain,words:words,scriptID:this.scriptID})
          this[attrId]=null
          this[attrName]=[]
        }
        
    },
      async  deleteSW(id){

           await   this.$store.dispatch('deleteSW',{id:id})
        this.orgSwId=null
        this.orgSw=[]

        
    },
  

 },
  mounted() {

this.show= this.showModal


  },
watch: {
  showModal(val) {
    this.show = val
    if(val){
       //this.$store.dispatch('getSpecialWords') 
    }
  }
},

}
  </script>     

  <style>
  .v-field__append-inner{
    align-items: center;
  }
  .togg{ background: #E4E7ED}
 
  </style>  