/* eslint-disable */
/* eslint-disable */

import Vuex from 'vuex';
import {langMethods}  from '@/mixins/langConxtant.js'
import axios from 'axios';
import router from '@/router'
const apiURL =document.location.host.indexOf('test')==-1&&document.location.host.indexOf('localhost')==-1?process.env.VUE_APP_SERVER_URL:process.env.VUE_APP_TESTSERVER_URL


export default new Vuex.Store({
  state: {
    apiURL : apiURL, 
    located: localStorage.getItem('located') ||'en',
    status: '',
    errorStateCount:0,
    token: localStorage.getItem('token') || '',
    userID:localStorage.getItem('userID') ||null,
    username:localStorage.getItem('username') || '',
    user :{},
    needPassChange:false,
    role:localStorage.getItem('role') ||null,
    specialWords:[],
    actors:[],
    topLearners:[],
    topScripts:[],
    scriptsReport:[],
    learnDinamic:[],
    students:[],
    scripts:[],
    scriptTypes:[],
    mapKey:100,
    updateMap:false,
    emotions:[],
    tabDialog:0,
    viewSearch:false,
    order:localStorage.getItem('order') ||null,
    snackbar:{
      show:false,
      text:'',
      color:''
    },
    residues:{
        "id": 0,
        "course": 0,
        "learners": 0,
        "users": 0,
        "seconds": 0,
        "courseTotal": 0,
        "usersTotal": 0,
        "secondsTotal": 0,
        "learnersTotal": 0,
        "minute": 0,
        "minuteTotal": 0
    },
    script:{
      id:null,
      actorId:null,
      newDialogID:null,
      lastDialogID:null,
      type:"",
      name:"",
      desc:"",
      createDate:"",
      type:"",
      filteredPlainText:[],
      dialogs:[
          {
          id:1,
          attributes:{
            answers:[],
            dialog:"",
            dialog_id:null
          }
        }
      ],
      plainText:[
        {
          type:"",
          id:null,
          text:"",
          dID:null,
          nextDialogId : null,
          elemID : null,
          positions:[]
        }
      ],
      dialogsOnly:[
        {
          type:"",
          id:null,
          text:"",
          dID:null,
          nextDialogId : null,
          elemID : null,
          positions:[]
        }
      ],
      searchIDs:[],
    }
  },
  getters : {
    getUser: state=> state.user,
    getRole: state=> state.role,
    needPassChange: state=> state.needPassChange,
    getOrder : state=> JSON.parse(state.order),
    getUsername: state=> state.username,
    getUserID: state=> state.userID,
    getLocated: state => state.located,
    students: state => state.students,
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    scripts: state => state.scripts,
    script: state => state.script,
    residues: state => state.residues,
    scriptTypes:state=>state.scriptTypes,
    getScriptName:state=>state.script.name,
    getScriptType:state=>state.script.type,
    getScriptId:state=>state.script.id, 
    getViewSearch:state=>state.viewSearch,
    filteredPlainText: state => state.script.filteredPlainText,
    plainText: state => state.script.plainText,
    dialogsOnly: state => state.script.dialogsOnly,
    searchIDs: state => state.script.searchIDs,
    mapKey: state => state.mapKey,
    emotions:state => state.emotions,
    actors:state => state.actors,
    tabDialog:state => state.tabDialog,
    snackbar:state =>state.snackbar,
    getSpecialWords:state => state.specialWords,
    apiURL : state => state.apiURL,
    token : state => state.token,
    getScriptsReport: state => state.scriptsReport,
    getTopLearners:state => state.topLearners,
    getTopScripts:state => state.topScripts,
    getLearnDinamic:state => state.learnDinamic
    
    
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    setSnackbar(state,{data,color}){
      state.snackbar.show = true
      state.snackbar.text = data
      state.snackbar.color = color
    },
    setConfirmPass(state){
      state.needPassChange=false
    },
    setRole(state,data){
      localStorage.setItem('role', data)
      state.role=data
    },
    setStudents(state,data){
      state.students=data

    },
    setResidues(state,data){
      state.residues=data

    },
    setOrder(state,data){
      
      state.order=JSON.stringify(data)
      localStorage.setItem('order', JSON.stringify(data))
    },
    removeOrder(state){
      localStorage.removeItem('order')
    },
    setTabDialog(state,data){
      state.tabDialog = data
     
        state.viewSearch=data==1?true:false
        
      
    },
    setAnswers(state,{data,dlgId,isNew,answID,needUpdate}){
      
      let curDlg=state.script.dialogs.find(d=>d.id==dlgId)

      if(isNew){
        curDlg.attributes.answers.push(data)
      }
      
      curDlg.attributes.answers.map(a=>{

          if(!isNew){
            if(a.id==data.id){
             
              a.next_dialog_id=data.next_dialog_id
              a.feedback=data.feedback
              a.isFinal=data.isFinal
              a.answer=data.answer
           
            }
         }else{
          a.results={resultScore: null, resultText: null}
         }
        return a
      })
      this.commit('setPlainText',{data:state.script.dialogs,needUpdate:needUpdate})


   


    },

    setPlainText(state,{data,needUpdate}){
      const dl = data.length;
      let plainText = [];
      for(let _d = 0; _d < dl; _d++) {
        let dID = data[_d].id
        plainText.push(
          {type : "d", 
          id : data[_d].id, 
          notVisible:false,
          kw:data[_d].attributes.keyWords?data[_d].attributes.keyWords.kw:[],
          emotion:data[_d].attributes.emotion.data?data[_d].attributes.emotion.data.id:null,
          dID : dID,
          nextDialogId : null,
          x:data[_d].attributes.x,
          feedback:data[_d].attributes.feedback,
          y:data[_d].attributes.y,
          num: _d+1+'.',
          colorType:data[_d].attributes.type=='speech'?'d':data[_d].attributes.type,
          elemID : _d+1,
          text : data[_d].attributes.dialog , 
          positions:[]
        });
        let al = data[_d].attributes.answers.length;
        if(data[_d].attributes.type=='message')
        continue
        for(let _a = 0; _a < al; _a++) {
          plainText.push(
            {type : "a", 
            colorType:"a",
            kw:data[_d].attributes.keyWords?data[_d].attributes.keyWords.kw:[],
            emotion:data[_d].attributes.answers[_a].emotion?data[_d].attributes.answers[_a].emotion.id:null,
            notVisible:data[_d].attributes.type=='finish',
            id : data[_d].attributes.answers[_a].id, 
            text : data[_d].attributes.answers[_a].answer, 
            isFinal : data[_d].attributes.answers[_a].isFinal, 
            dID : dID,
            feedback:data[_d].attributes.answers[_a].feedback,
            nextDialogId : data[_d].attributes.answers[_a].next_dialog_id,
            elemID : data[_d].attributes.answers[_a].answer_id,
            num: _d+1+'.'+data[_d].attributes.answers[_a].answer_id,
            viewFeedback:data[_d].attributes.answers[_a].feedback!=null,
            
          });
        
        }
      }
      state.script.plainText=plainText
      state.script.filteredPlainText=plainText
      if(needUpdate){
        state.mapKey++
      }

      
    },


    setSpecialWords(state,data){
      state.specialWords=data
    },
    setLocated(state,data){
      localStorage.setItem('located', data)
      state.located=data
    },
    setViewSearch(state,data){
      state.viewSearch = data
    },
    setScriptTypes(state,data){
      state.scriptTypes=data.filter(s=>{return s.id!=1}).sort(function(a, b) {
        return parseFloat(b.id) - parseFloat(a.id);
    });
    },
    setDialogID(state,id){
      state.script.lastDialogID=id
      
    },
    setScripts(state,data){
      state.scripts=data
      state.script.lastDialogID=null
    },
    setEmotions(state,data){
      let em=[]
      data.map(d=>{
        let itm={}
        itm.id=d.id;
        itm.local_name=d.attributes.local_name;
        itm.default=d.attributes.default;
        itm.name=d.attributes.name;
        itm.url=d.attributes.editorIcon.data.attributes.url;
        em.push(itm)
      })
      
      state.emotions=em
    },

    setActors(state,data){
      let acts=[]
      data.map(d=>{
        let itm={}
        itm.id=d.id;
        itm.voiceId=d.attributes.voice.data.id;
        itm.name=d.attributes.name;
        itm.url=d.attributes.icon.data.attributes.url;
        acts.push(itm)
      })
      state.actors=acts

    },

    setScriptsReport(state,data){
      state.scriptsReport=data
      
    },
    setTopScripts(state,data){
      state.topScripts=data.data 
     
    },
    setTopLearners(state,data){
      state.topLearners=data.data

    },
    setLearnDinamic(state,data){
      state.learnDinamic=data.data

    },

    setScript(state,data){
      state.script.id=data.id
      let loc=state.located
      state.script.type=data.attributes.script_type.data.attributes.type
      state.script.type=data.attributes.script_type.data.attributes.type
      state.script.name=data.attributes.name
      state.script.desc=data.attributes.description
      state.script.createDate=data.attributes.createDate
      state.script.actorId=data.attributes.defaultActor.data?data.attributes.defaultActor.data.id:null;
      
    },
    setSearchIDs(state,data){
      
      state.script.searchIDs=data
    },
    setfilteredPlainText(state,data){
      state.script.filteredPlainText=data
      state.mapKey++
    },
    updateMap(state,val){
        setTimeout(() => {
          state.mapKey++
          },500) 
    },
    setDialogs(state,data){
      
     // state.script.lastDialogID=null;
     let dlgs=data.map(d=>{
      d.viewFeedback=d.attributes.feedback!=null&&d.attributes.feedback!=''
      d.attributes.answers.map(a=>{
        a.viewFeedback=a.feedback!=null&&a.feedback!=''
        return a
      })
      return d
     })
      state.script.dialogs=dlgs
      

      if(state.script.dialogs.length&&state.script.lastDialogID==null){
       
        if(state.script.newDialogID==null){
        state.script.lastDialogID=state.script.dialogs[0].id
       }
       else{
        state.script.lastDialogID=state.script.newDialogID
        state.script.newDialogID=null
       } 
       

      }
      const dl = data.length;
      let plainText = [];
      let dialogsOnly = [];
      for(let _d = 0; _d < dl; _d++) {
        let dID = data[_d].id
        plainText.push(
          {type : "d", 
          id : data[_d].id, 
          notVisible:false,
          kw:data[_d].attributes.keyWords?data[_d].attributes.keyWords.kw:[],
          emotion:data[_d].attributes.emotion.data?data[_d].attributes.emotion.data.id:null,
          dID : dID,
          nextDialogId : null,
          feedback:data[_d].attributes.feedback,
          x:data[_d].attributes.x,
          y:data[_d].attributes.y,
          num: _d+1+'.',
          colorType:data[_d].attributes.type=='speech'?'d':data[_d].attributes.type,
          elemID : _d+1,
          text : data[_d].attributes.dialog , 
          positions:[]
        });
        dialogsOnly.push(
          {type : "d", 
          notVisible:false,
          
          id : data[_d].id, 
          text : data[_d].attributes.dialog, 
          colorType:data[_d].attributes.type=='speech'?'d':data[_d].attributes.type,
          dID : dID,
          nextDialogId : null,
          elemID : _d+1,
          num: _d+1+'.',
        });
        let al = data[_d].attributes.answers.length;
        if(data[_d].attributes.type=='message')
        continue
        for(let _a = 0; _a < al; _a++) {
          plainText.push(
            {type : "a", 
            colorType:"a",
            kw:data[_d].attributes.keyWords?data[_d].attributes.keyWords.kw:[],
            emotion:data[_d].attributes.answers[_a].emotion?data[_d].attributes.answers[_a].emotion.id:null,
            notVisible:data[_d].attributes.type=='finish',
            id : data[_d].attributes.answers[_a].id, 
            text : data[_d].attributes.answers[_a].answer, 
            isFinal : data[_d].attributes.answers[_a].isFinal, 
            dID : dID,
            feedback:data[_d].attributes.answers[_a].feedback,
            nextDialogId : data[_d].attributes.answers[_a].next_dialog_id,
            elemID : data[_d].attributes.answers[_a].answer_id,
            num: _d+1+'.'+data[_d].attributes.answers[_a].answer_id,
            viewFeedback:data[_d].attributes.answers[_a].feedback!=null,
            
          });
        
        }
      }
      state.script.plainText=plainText
      state.script.filteredPlainText=plainText
      state.script.dialogsOnly=dialogsOnly
      state.mapKey++
    },
    setDialog(state,data){

      state.script.newDialogID=data.id
      //state.script.lastDialogID=null
    },
    deleteDlg(state,id){
      state.script.dialogs=state.script.dialogs.filter(d=> {return d.id!=id})
      state.script.lastDialogID=null

    },
    
    auth_success(state, data){
      state.status = 'success'
      state.token = 'Bearer '+data.jwt
      if(data.user){
        state.userID=data.user?data.user.id:null
        state.user=data.user?data.user:null
        state.needPassChange=data.user?data.user.needChange :null
        if(data.user.username){
          state.username=data.user.username
        }
      }
      if(data.userID){
        state.userID=data.userID
      }
      if(data.name){
        state.username=data.name
      }
      if(data.role){
        state.role=data.role
      }


      

    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
    searchResultPositions(state,data){
      state.searchResultPositions = data;
    },
    searchResultPositionsCounter(state,data){
      state.searchResultPositionsCounter = data;
    }
  },
  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: apiURL+'/api/auth/local', data: user, method: 'POST' })
        .then(resp => {
          const token = 'Bearer '+resp.data.jwt
          const user = resp.data.user
          localStorage.setItem('token', token)
          localStorage.setItem('userID', user.id)
          axios.defaults.headers.common['Authorization'] = token
          if(resp.data.user.username){
            localStorage.setItem('username', resp.data.user.username)
            }
          commit('auth_success', resp.data)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('username')
          localStorage.removeItem('userID')
          reject(err)
        })
      })
  }, 
   async remindPass({commit}, {email}){
    return   await   axios .post(apiURL+'/api/auth/forgot-password',
    {"email":email})
    .then(resp => {
      return resp.data
    })

  },
   confirmPass({commit}, data){
    return axios .post(apiURL+'/api/auth/reset-password',
    data)
    .then(resp => {
      const token = 'Bearer '+resp.data.jwt
      const user = resp.data.user
      localStorage.setItem('token', token)
      localStorage.setItem('userID', user.id)
      axios.defaults.headers.common['Authorization'] = token
      if(resp.data.user.username){
        localStorage.setItem('username', resp.data.user.username)
        }
      commit('auth_success', resp.data)
      
      return resp
    })
    .catch(err => {
      commit('auth_error')
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      localStorage.removeItem('userID')
     
    })

  },
  async scriptList({ dispatch,commit,state}, ){

  
      
     return await axios
      .get(apiURL+'/api/scripts-lists',{
       headers: {
          Authorization: state.token,
        },
      } )
     
      .then(response => {
        commit('setScripts', response.data.data)

      })
   
    

  },
  getScriptType({commit,state}, ){
    return new Promise((resolve, reject) => {
      
      axios
      .get(apiURL+'/api/script-types',{
        headers: {
          Authorization: state.token,
        },

      } )
     
      .then(response => {
        commit('setScriptTypes', response.data.data)

      })
    })
  

  },
  getActors({commit,state}, ){
    return new Promise((resolve, reject) => {
      
      axios
      .get(apiURL+'/api/actors',{
        headers: {
          Authorization: state.token,
        },

      } )
     
      .then(response => {
        commit('setActors', response.data.data)

      })
    })
  

  },
  async getStudents({commit,state},){
   await axios
    .post(this.state.apiURL + '/api/lessons/learners')
    .then(response => {  
      commit('setStudents', response.data.data)
        
    })

  },
  async getScript({commit,state},id ){
   
      
    return await  axios
      .get(apiURL+'/api/scripts-lists/'+id ,{
      // params:{id:id},
       headers: {
          Authorization: state.token,
        },
      } )
     
      .then(response => {
        
        commit('setScript', response.data.data)
        return true

      })
   
  

  },
  async getDialogs({commit,state},id ){
    
  
    return   await   axios
      .get(apiURL+'/api/dialogs',{
       params:{scriptID:id},
       headers: {
          Authorization: state.token,
        },
      } )
     
      .then(response => {
       
        commit('setDialogs', response.data.data)
        return response

      })
   
  
  

  },
  getEmotions({commit,state},id ){
    return new Promise((resolve, reject) => {
      
      axios
      .get(apiURL+'/api/emotions',{
       headers: {
          Authorization: state.token,
        },
      } )
     
      .then(response => {
        commit('setEmotions', response.data.data)

      })
    })
  

  },

 
setScriptState({commit,state,dispatch},{id,status}){
 
   
return   axios
    .get(apiURL+'/api/scripts-lists/'+id+'/changeStatus/'+status)
          .then(response => {
            if(!response.data.ok){
              let message
              if(response.data.messages){
                message=response.data.messages.join('; \r\n')
              }else{
                message=response.data.message
              }
              
              
              commit('setSnackbar',{data:message,color:'red'})
              
            }
            dispatch('scriptList')
            return response.data.ok
          })

      
},

getResidues({commit,state,dispatch}){
  return   axios
    .get(apiURL+'/api/residues' )
          .then(response => {
            commit('setResidues',response.data)
     })
},



async getTopLearners({commit,state,dispatch}){
  return   axios
  .post(apiURL+'/api/lessons/reports',
  {"data":{
    "report" : "reportTopTimeByLearners",
    "options" : { "limit" : "8"}
    }
  }

  ).then(response => {


    commit('setTopLearners',response.data)
  })


},

async getScriptStat({commit,state,dispatch}){
      
   
    
   return axios
   .post(apiURL + '/api/lessons/reports',{
   /* headers: {
      Authorization:token,
     },*/
     data:{
       "report": "reportByScriptsTotal",
       "options": "",
     }
     
  } )
  .then(resp => {
  
    commit("setScriptsReport",resp.data)
    
   

  })  
 },
async getTopscript({commit,state,dispatch}){
  return   axios
  .post(apiURL+'/api/lessons/reports',
  {"data":{
    "report" : "reportTopTimeByScripts",
    "options" : { "limit" : "8"}
    }
  }

  ).then(response => {

    commit('setTopScripts',response.data)
   
  })


},
async getLearnDinamic({commit,state,dispatch}){
  return   axios
  .post(apiURL+'/api/lessons/reports',
  {"data":{
    "report" : "reportLearnDinamic",
    "options" : ""
    }
  }

  ).then(response => {

    commit('setLearnDinamic',response.data)

   
  })


},






validateScript({commit,state,dispatch},{id}){
return   axios
    .get(apiURL+'/api/scripts-lists/'+id+'/checkScript' )
          .then(response => {
            if(!response.data.ok){
              let message=response.data.messages.join('; \r\n')
              
              commit('setSnackbar',{data:message,color:'red'})
              
            }else{
              commit('setSnackbar',{data:"OK",color:'green'})
            }
            dispatch('scriptList')
            return response.data.ok
          })

      
},

 async reposition({commit},{scriptId}){
  return await  axios
  .get(apiURL+'/api/dialog/'+scriptId+'/rePosition' )
  .then(res=>
    {return res} )

},

   addScript({commit,state,dispatch}, {name,desc,scriptTypeId,file,actorId}){

   
      let data={
         
        "description": desc,
        //"users_permissions_user":`${state.userID}`,
        "name": name,
        "script_type": scriptTypeId,
        "defaultActor":actorId,

          
        }
        data.fromIspring=file?true:false
     
       return axios
      .post(apiURL+'/api/scripts-lists',{
      
       
          data:data
        
      },{
        headers: {
          Authorization: state.token,
        },
      } ).then(response => {
        commit('setDialogID',null)

       if(response.data){

              
              if(file){

              dispatch('loadZip',{id:response.data.id,file:file})
              .then(()=> {return true})
            }else{
              router.push('dialog/'+response.data.id)
            }
        }else{
          return true
        }
      

      })

    
  

  },
  editScript({commit,state,dispatch}, {scriptId,name,desc,actorId}){

    return new Promise((resolve, reject) => {
      
    axios
      .put(apiURL+'/api/scripts-lists/'+scriptId,{
          data:{
          "description": desc,
          "users_permissions_user":`${state.userID}`,
          "name": name,
          "defaultActor":actorId
          }
        
      },{
        headers: {
          Authorization: state.token,
        },
      } ).then(response => {
        
      })

    })
  

  },

 

async loadZip({commit,state,dispatch},{id,file}){

  const formData = new FormData();
    let tUrl=apiURL+"/api/scripts-lists/"+id+"/importISpring"
   // console.log(file);
      //formData.append("file",new Blob([file], {type: 'application/zip'}),file.name, );
      formData.append("file",file );
     return await axios
        .post(tUrl, formData, {
          baseURL: tUrl,
          headers: {

            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          setTimeout(()=>{
            router.push('dialog/'+id)
            return true
          },5000)
          
        })
        .catch(() => {
         
        })
  },


async  addDialog({commit,state}, {scriptId,dialog,dialogId,type,em,x,y}){
    
   
      
    return await  axios
      .post(apiURL+'/api/dialogs',{
       headers: {
          Authorization: state.token,
        },
          data:{

          "dialog": dialog,
          "script_id": scriptId,
          "dialog_id":dialogId,
          "type": type,
          "x" : x,  
          "y" : y, 
          "emotion":em?{"id":em }:null
          }
        
      } )
     
      .then(response => {
        
        commit('setDialog', response.data)
        return response.data
      })
  
  

  },
  addDialogName({commit,state}, {scriptId,dialog,dialogId,feedback}){

  
     return axios
      .put(apiURL+'/api/dialogs/'+dialogId,{

          data:{
          "dialog": dialog,
          "scriptID": scriptId,
          "feedback":feedback,
          "changedField": "main" 
         // "dialog_id":dialogId          
          }
        
      } )
      .then(response => {  
        return true
      })
  },
  setFinishText({commit,state}, {scriptId,score,text,dialogId}){

  
    return axios
     .put(apiURL+'/api/dialogs/'+dialogId,{

         data:{
         "resultText": text,
         "resultScore": score?score:null,
         "scriptID": scriptId,
         "changedField": "score" 
        // "dialog_id":dialogId          
         }
       
     } )
     .then(response => {  

     })
 },
 async setFinishAnswer({commit,state},{id,scriptId,score,text,dialogId} ){
 let tData={
    "resultText": text,
    "resultScore": score?score:null,
    "scriptID": scriptId,
    "dlgId":dialogId,
    "changedField": "score" 
   // "dialog_id":dialogId          
    }
  await  axios
  .put(apiURL+'/api/answers/'+id,
    {data:tData}
   ).then(resp => {
    commit('setAnswers',{data:resp.data,dlgId:dialogId,answID:id,needUpdate:true})
  }) 
  
 },
  setKeyWord({commit,state,dispatch}, {id,kw,scriptId}){
    return axios
    .put(apiURL+'/api/dialogs/'+id,{

        data:{
       "keyWords":{"kw":kw},
       "changedField": "main",
        "scriptID":scriptId          
        }
      
    } )
    .then(response => {  

     dispatch( 'getDialogs',scriptId)
      return response
    })
  },
  nodeMove({commit,state}, {scriptId,dialogId,x,y}){

    return new Promise((resolve, reject) => {
      axios
      .post(apiURL+'/api/dialog/userPosition',{

        
          "dialogID": dialogId,
          "x":x,
          "y":y,
         // "dialog_id":dialogId          
          
        
      } )
      .then(() => {  
        let cDialog=state.script.dialogs.find(d=>d.id==dialogId)
        cDialog.attributes.x=x
        cDialog.attributes.y=y
        this.commit('setPlainText',{data:state.script.dialogs,needUpdate:false})
      })
    })
  },
  

  setEmotionDlg({commit,state}, {scriptId,dialogId,emId}){
    return new Promise((resolve, reject) => {
      axios
      .put(apiURL+'/api/dialogs/'+dialogId,{

          data:{
          "scriptID": scriptId,
          "emotion":{id:emId},
          "changedField": "main" 
        //  "dialog_id":dialogId          
          }
        
      } )
      .then(response => {  
      
      })
    })
  },
  setEmotionAnsw({commit,state}, {dialogId,answerId,emId,scriptID}){

    return new Promise((resolve, reject) => {
      axios
      .put(apiURL+'/api/answers/'+answerId,{

          data:{
          "emotion":{id:emId},
          "dialog_id":dialogId,
          "scriptID":scriptID,
          "changedField": "main"           
          }
        
      } )
      .then(response => {  
        
      
      })
    })
  },
  async getScorm({commit,state}, id){
    return  axios 
    .post(apiURL+'/api/scripts-lists/'+id+'/exportSCORM')
    .then(res => {  
        return res.data
    })

  },

 async deleteDialog({commit,state,dispatch}, {scriptId,dialogId}){

    
    await  axios
      .delete(apiURL+'/api/dialogs/'+dialogId,{ 
        params:{scriptID:scriptId},
      } ).then(
        commit('deleteDlg',dialogId),
        setTimeout(()=>{
          commit('setSnackbar',{data:langMethods.methods.getLangConst('deleted')})
        },500)
        
      
        
      )

    
  },
  deleteScript({commit,state}, {scriptId}){

    return new Promise((resolve, reject) => {
      axios
      .delete(apiURL+'/api/scripts-lists/'+scriptId,{ 
      } ).then(
        commit('setSnackbar',{data:langMethods.methods.getLangConst('deleted')})
      )

    })
  },

 async addAnswer({commit,state}, {id,answer,answerId,dialogId,needUpdate,nextDialogId,feedback,isFinal,scriptID}){
    
      if(id=='new'){
        let tData={}
        tData.answer=answer
        tData.scriptID=scriptID
        tData.answer_id=answerId
        tData.dialog_id=dialogId
        if(isFinal!=null&&isFinal!=undefined){
          tData.isFinal=isFinal
        }
    return await  axios
      .post(apiURL+'/api/answers',{
          data:tData  
      } ).then(resp => { 
        commit('setAnswers',{data:resp.data,dlgId:dialogId,isNew:true,needUpdate:needUpdate})
        return resp.data
      })    

    }else{
      if(dialogId==nextDialogId){
        commit('setSnackbar',{data:langMethods.methods.getLangConst('notLoop'),color:'red'})
        return
      }

      let tData={}
      tData.answer=answer
      tData.dialog_id=dialogId
      tData.answer_id=answerId
      tData.scriptID=scriptID
      tData.changedField= "main" 
    //  tData.next_dialog_id=nextDialogId
        if(nextDialogId==null){
          tData.next_dialog_id=nextDialogId
        }
        if(nextDialogId!=undefined){
          tData.next_dialog_id=nextDialogId
        }
        if(feedback!=null){
          tData.feedback=feedback
        }
        if(isFinal!=null&&isFinal!=undefined){
          tData.isFinal=isFinal
          if(isFinal==true){
            tData.next_dialog_id=null
          }
        }
        await  axios
      .put(apiURL+'/api/answers/'+id,
        {data:tData}
       ).then(resp => {
        commit('setAnswers',{data:resp.data,dlgId:dialogId,answID:id,needUpdate:needUpdate})
      })   

    }
   
  

  },

  deleteAnswer({commit,state}, {id,dialogId}){
    
    return new Promise((resolve, reject) => {

      axios
      .delete(apiURL+'/api/answers/'+id+'?dialogId='+dialogId,{ } )  

    
    })
    
  

  },
  setContact({commit,state},{data}){
    return  axios
      .post(apiURL+'/api/createContact',{data:data})
      .then(res=>{

        return res
      })  

    
  

  },
  async getSpecialWords({commit,state}){
    await axios
    .get(apiURL+"/api/specialwords")
      .then(resp => {
        commit('setSpecialWords',resp.data.data)
      })
 },

 async addSW({commit,state,dispatch},{id,type,domain,words,scriptID,} ){
  if(id=='new'){
 await axios
  .post(apiURL+"/api/specialwords",{
      data:{
      "type": type,
      "domain": domain,
      "words":words, 
      "scriptID":scriptID
      }   
  } ).then(resp => {

    dispatch('getSpecialWords')
  })
  }else{
    await axios
  .put(apiURL+"/api/specialwords/"+id,{
      data:{
      "type": type,
      "domain": domain,
      "words":words,
      "scriptID":scriptID 
      }   
  } ).then(resp => {

    dispatch('getSpecialWords')
  })

  }
 },
 async deleteSW({commit,state,dispatch},{id}){
  await axios
  .delete(apiURL+"/api/specialwords/"+id)
  .then(resp => {

    dispatch('getSpecialWords')
  })
  

 },

 async getUser(id){
    await axios
    .get(apiURL+"/api/users/"+localStorage.getItem('userID'))
      .then(resp => {
        if(resp.data.username){
          localStorage.setItem('username', resp.data.username)
          }
      })
 },


 async register({commit,dispatch}, user){

      commit('auth_request')
    return await axios({url: apiURL+'/api/scripts-lists/newEditor', data:{data:user} , method: 'POST' })
      .then(resp => {
       if(resp.status==200){
        const token = 'Bearer '+resp.data.jwt
        localStorage.setItem('token', token)
       // localStorage.setItem('userID', resp.data.userID)
        axios.defaults.headers.common['Authorization'] = token
       // dispatch('getUser',resp.data.userID)
        commit('auth_success', resp.data)
        router.push('/')
        }
      })

      .catch(err=> {
        localStorage.removeItem('token')


      })
    
  },
  async registerDemo({commit,dispatch}, user){

    commit('auth_request')
  return await axios({url: apiURL+'/api/scripts-lists/newDemo', data:{data:user} , method: 'POST' })
    .then(resp => {
     if(resp.status==200){
      const token = 'Bearer '+resp.data.jwt
      localStorage.setItem('token', token)
      if(resp.data.userID){
        localStorage.setItem('userID', resp.data.userID)
      }
      if(resp.data.name){
        localStorage.setItem('username', resp.data.name)
        }
      if(resp.data.role){
        localStorage.setItem('role', resp.data.role)
      }
     // localStorage.setItem('userID', resp.data.userID)
      axios.defaults.headers.common['Authorization'] = token
     // dispatch('getUser',resp.data.userID)
      commit('auth_success', resp.data)
      router.push('/')
      }
    })

    .catch(err=> {
      localStorage.removeItem('token')


    })
  
},
  checkRole({commit}){
    axios
    .post(apiURL+'/api/scripts-lists/checkRole')
     .then(res=>{
      commit('setRole', res.data.role)
      
     })
  },
  redirectG({commit,dispatch},query ){
    return new Promise((resolve, reject) => {
     // commit('auth_request')
 
     axios
     .get(apiURL+'/api/auth/google/callback',{
      params:query
    })

      .then(res => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then(resp => {
        const token = 'Bearer '+resp.data.jwt
          const user = resp.data.user
          localStorage.setItem('token', token)
          localStorage.setItem('userID', user.id)
          localStorage.setItem('username', user.username);
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', resp.data)
          dispatch('checkRole')
          setTimeout(() => router.push('/'), 500); 

      })
      .catch(err => {
        commit('auth_error', err)
        localStorage.removeItem('token')
        reject(err)
      })
    })
  },
  
  logout({commit}){
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('userID')
      localStorage.removeItem('username')
      localStorage.removeItem('role')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  }
  ,
  getSearchIDs({commit,state}, {searchStr}) {
    return new Promise((resolve, reject) => {
      axios
      .post(apiURL+'/api/scripts-lists/search',{
         headers: {
          Authorization: `Bearer ${state.token}`,
         },
         data:{
          "scriptID": state.script.id,
          "searchStr": searchStr,
        }   
      } )
      .then(response => {
        
        commit('setSearchIDs', response.data.data)

      })    

    });
  },
  
}
})
