import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue'
import {langMethods}  from './mixins/langConxtant.js'
import Vuex from 'vuex';
//import gAuthPlugin from 'vue3-google-oauth2'
import vuetify from './plugins/vuetify'
//import "v-network-graph/lib/style.css"
import router from './router'
import store from './store'
import Axios from 'axios'
import "regenerator-runtime/runtime";
//import MyPlugin from './searchReplace'
const app = createApp(App)
import { loadFonts } from './plugins/webfontloader'
app.config.globalProperties.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  app.config.globalProperties.$http.defaults.headers.common['Authorization'] = token
}

//let gauthClientId='1006160745276-eeivdq02qubrn5pji0omgfsstvgljthj.apps.googleusercontent.com'
loadFonts()
Axios.interceptors.response.use(
  response =>{  
  return response
},
error => {
if(error){
  console.log(error);
  if (error.response.status === 401) {
    window.location.href = '/login';
  }
  if (error.response.status === 400) {
    
    if(error.response.data.error.details){
      store.commit('setSnackbar',{data:error.response.data.error.details.Error, color:"red"})
    }else{
    store.commit('setSnackbar',{data:langMethods.methods.getLangConst('400'), color:"red"})
    }
  }
  if (error.response.status === 403) {
    store.commit('setSnackbar',{data:langMethods.methods.getLangConst('403'), color:"red"})
  }
  if (error.response.status === 500) {
    store.commit('setSnackbar',{data:langMethods.methods.getLangConst('500'), color:"red"})
    
  }
  if (error.response.status === 405) {
    store.commit('setSnackbar',{data:langMethods.methods.getLangConst('400'), color:"red"})
    
  }
  if(error.toJSON().message === 'Network Error'){
    store.commit('setSnackbar',{data:'Service unavailable, please try again later'})
    
  }
}
 return error
});


app.use(router)
app.use(store)
/*app.use(gAuthPlugin,{
    clientId:gauthClientId,
    plugin_name: "chat",
    scope:'email',
    prompt:'consent'
  })*/
  app.use(Vuex)
  //.use(MyPlugin)
  app.use(vuetify)
  app.mixin(langMethods)
  app.mount('#app')
