<template>
  <div id="app">
      <v-app :key="key">
        <v-app-bar
       theme="dark"
      app
      height="48"
      color="#242D3F"
    >

       
          <div class="d-flex pa-2">
         <a class="ma-2" href="/"> <v-img    width="30px" :src='require("@/assets/m.png")'></v-img></a>
        
          <v-tabs 
          v-if="isLoggedIn&&$route.name!='home'" 
          v-model="tab"
          >
            <v-tab class="ml-2" to="/scripts"  ><v-icon>mdi-view-list</v-icon> <div class="ml-2"> {{getLangConst('myScripts')}} </div> </v-tab>
           <!-- <v-tab to="/about" >{{getLangConst('about')}}</v-tab>-->
          </v-tabs>
          
        
          </div>
          <template v-if="isLoggedIn&&$route.name=='dialog'" >
          <div style="max-height:40px;min-width:160px" class="mx-5   lName s-13 ">{{scriptName}}</div>
         <div class="mx-2 mr-5" style="min-width:170px"><v-chip v-if="scriptName" size="small"  class="text-white text-center ml-5" color="rgba(80, 213, 255, 0.29)" label>{{typeName}}</v-chip></div> 
          </template> 
        <v-spacer></v-spacer>
       <!-- <v-btn variant="text"  v-model="showKw" @click="validateScript()" > {{getLangConst('validate')}}</v-btn>-->
       <v-spacer></v-spacer>
        <template v-if="isLoggedIn&&$route.name=='dialog'" >
          <v-divider class="mx-2 " vertical></v-divider>
          <div style="min-width:100px"> 
          <v-btn variant="text"   size="small" @click="setStatus()" class=" ma-2" >{{getLangConst('toPublish')}}  </v-btn>
           </div>
        <v-divider class="mx-2 " vertical></v-divider>
        <v-tabs
          
          v-model="tabDialog"
          >
            
            <v-tab class="s-13" >{{getLangConst('chart')}}</v-tab>
            <v-tab class="s-13" >{{getLangConst('list')}}  </v-tab>
         </v-tabs>
          <v-divider class="mx-2 " vertical></v-divider>
          <v-icon @click="viewSearch=!viewSearch" :color="viewSearch?'#00d1ff':''" class="mx-2 " >mdi-magnify</v-icon>

           <!-- <v-menu  v-if='lastDialogID'  >
              <template v-slot:activator="{  props }">
                <v-btn  size="small" variant="text" v-bind="props"  icon="mdi-dots-vertical">
                
                </v-btn>
              </template>
              <v-list> 
                <v-list-item @click="deleteDialog()" width="200px">
                  <v-icon class=" mr-2">mdi-delete</v-icon> {{getLangConst('delete')}}  
                </v-list-item>
                 <v-list-item @click="loadWords();showModal=!showModal" width="200px">
                  <v-icon class=" mr-2">mdi-text</v-icon> {{getLangConst('dictionaries')}}  
                </v-list-item>
                
              </v-list>
            </v-menu>-->



            <v-divider class="mx-2 " vertical></v-divider>
            

          </template>
            <!-- <v-btn @click="setLocated" style="text-transform:uppercase;">{{located}}</v-btn>
           <v-tooltip   location="bottom">
              <template  v-slot:activator="{ props }" >   
               <span v-bind="props">   
            <a class="pa-2 "  v-if="isLoggedIn" href="/help"> <v-icon size="20" color="#fff" class="ma-2  ">mdi-help-circle-outline</v-icon></a>
            </span>
            </template>
           <span style="color:#fff">{{getLangConst('howItWorks')}} </span> 
            </v-tooltip> -->
            <v-menu    >
              <template v-slot:activator="{  props }">
                <v-icon  size="20" color="#fff"  v-bind="props" class="ma-2">mdi-help-circle-outline</v-icon>
              </template>

              <v-list density="compact" class="mt-3 rounded-lg" theme="light" >  
                <v-list-item class="mx-3 mt-3 rounded-lg"  width="250px" @click="$router.push({ path: `/help/` })">
                   <v-icon v-if="isLoggedIn"   class="mr-1"  >mdi-cogs</v-icon>  <span class="s-15" >{{getLangConst('howItWorks')}}</span>
                 </v-list-item>
                 <v-list-item class="mx-3 mt-3 rounded-lg"  width="250px" @click="$router.push({ path: `/faq/` })">
                  <v-icon   class="mr-1"  >mdi-help-circle-outline</v-icon> <span class="s-15" >{{getLangConst('faq')}}</span>
                 </v-list-item>
                 
              </v-list>
            </v-menu>

        <v-menu  v-if='isLoggedIn'  >
              <template v-slot:activator="{  props }">
              <!--  <v-btn color="#4863FF"  size="small" variant="icon" v-bind="props" icon="mdi-account-outline" >
                
                </v-btn>-->
                <v-avatar style="color:#fff;min-width:30px;" v-bind="props"   size="30" class="ma-3 mr-5" color="#4863FF">
                 
      <span  v-if="username">  {{getInitials(username)}}</span>
      <v-icon v-else>mdi-account-outline</v-icon>
      </v-avatar>
              </template>
              <v-list density="compact" class="mt-3 rounded-lg" theme="light" >  
                <div class=" text-left ml-8 font-weigth-bold"><span class="font-weight-bold">{{username}}</span></div>
              <v-list-item class="mx-3 mt-3 rounded-lg"  width="250px" @click="$router.push({ path: `/account/` })">
                 <v-icon size="20px" class=" mr-2 mb-1">mdi-account-outline</v-icon> <span class="s-15" >{{getLangConst('personalProfile')}}</span>
                </v-list-item>
                 <v-list-item class="mx-3 rounded-lg" v-if="role=='editor'||role=='teacher'||role=='demo'" width="250px" @click="toTeacher()">
                 <v-icon size="20px" class=" mr-2 1">mdi-account-tie-outline</v-icon> <span class="s-15">{{getLangConst('teacherAccount')}}  </span>
                </v-list-item>
                <v-list-item class="mx-3 rounded-lg" width="250px" @click="logout">
                  <v-icon size="20px" class=" mr-3 mb-1">mdi-exit-to-app</v-icon>
                   <span  class="s-15"> {{getLangConst('exit')}}  </span>
                </v-list-item>
                
              </v-list>
            </v-menu>


    </v-app-bar> 
    <v-main class="mt-5"  >  
     
 <router-view  ></router-view>
      
 <v-dialog 
 fullscreen
    v-model="isMobile"

        transition="dialog-bottom-transition"
 
  >
   <v-card>
  <v-card-text>
  {{getLangConst('notMobile')}} 
  </v-card-text>
    </v-card> </v-dialog>
     
     </v-main> 
      <v-snackbar
        v-model="snackbar.show"
        timeout="4000"
        :color="snackbar.color"

        

        multi-line
      >
      
  {{snackbar.text}}

      </v-snackbar> 
      <change-pass
      :showModal="needPassChange"
      >

        </change-pass>

      
        <special-words
:located="located"
:showModal="showModal"
@cancel="showModal=!showModal"
@update="showModal=!showModal;"
>
  </special-words>
  
    </v-app>
  </div>
</template>
<script> 
import axios from 'axios';

import specialWords from "@/components/SpecialWords.vue";
import changePass from "@/components/ChangePass.vue";
export default { 
  name: "App",

   data() {
     return { 
     
      key:1,
      snackText:'',
      showModal:false
      };
     },
      components:{
          specialWords,
          changePass
      },
      computed : {
        sUrl(){return this.$store.getters.apiURL},
      isMobile(){ return this.$vuetify.display.mobile},
      needPassChange(){return this.$store.getters.needPassChange},
      username(){  return this.$store.getters.getUsername },  
      user(){return this.$store.getters.getUser},
      role(){return this.$store.getters.getRole},
      isLoggedIn (){  return  this.$store.getters.isLoggedIn},
      located (){  return  this.$store.getters.getLocated},
      token(){return this.$store.getters.token},
      scriptName(){  return  this.$store.getters.getScriptName},
      scriptId(){  return  this.$store.getters.getScriptId},
      typeName(){  return  this.getLangConst(this.$store.getters.getScriptType)},
      lastDialogID(){  return  this.$store.getters.script.lastDialogID},
      scriptData(){ return this.$store.getters.script},
       snackbar(){ return this.$store.getters.snackbar},

      tabDialog:{

        get(){
          return  this.$store.getters.tabDialog
        },
        set(val){
          this.$store.commit('setTabDialog',val)

        }
      },
      viewSearch:{
        get(){
          return  this.$store.getters.getViewSearch
        },
        set(val){
          this.$store.commit('setViewSearch',val)
        }
      },

      tab(){
        return this.$route.name=='dialog'?1:0
      }

      
    },
     
    methods: {
      toTeacher(){
        let url=this.sUrl.indexOf('test')==-1?'https://teacher.sonotiv.com/init?':'https://testteacher.sonotiv.com/init?'
 //url="http://localhost:8081/init?"
      url+='jw='+this.token
      url+='&locale='+this.located
            window.open(url, '_blank');
    },
       validateScript(){
      this.$store.dispatch('validateScript',{id:this.scriptId})

    },
     async setStatus(){

    await this.$store.dispatch('setScriptState',{id:this.scriptId,status:'Publish'})
    .then(res=> {
    if(res){
      this.$router.push('/scripts')
    }
    }
    )
  },
      

      deleteDialog(){
         let sDialog=this.scriptData.dialogs.find(d=>d.id==this.lastDialogID)
         
        if(sDialog.attributes.type!='speech'&&sDialog.attributes.type!='finish'){
        
        this.$store.commit('setSnackbar',{data:this.getLangConst('notDelete'),color:"red"})

          return
        }
        
      
       this.$store.dispatch('deleteDialog',{scriptId:this.$route.params.id,dialogId:String(this.lastDialogID)})
       this.key++
      },
      async handleSignOut() {
      
      try {
        await this.$gAuth.signOut();
        // console.log(this.$gAuth.signOut);
        this.user = '';
      } catch (error) {
        console.log(error);
      }
    },
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },
      loadWords(){
        this.$store.dispatch('getSpecialWords') 
      },
      created: function () {

        axios.interceptors.response.use(function (response) {


         /* return new Promise(function () {
          // if ((err.status === 401||err.status === 403) && err.config && !err.config.__isRetryRequest) {
            
            if (err.response.status === 401||err.response.status === 403){
              console.log(this);
               this.logout()
            }
            throw err;
          });*/
        return response;
          }, function (error) {
            console.log(error);


           // return Promise.reject(error);


        });
      },

    },
      mounted() {
 this.created();
  },
   watch: {
      tabDialog(val) {
        this.$store.commit('setTabDialog',val)


      }
    },
   };
 </script> 
<style lang="scss">
#app {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
.v-application * {
   font-family: Inter, sans-serif !important;
}
.v-tab--selected{
  color: #00d1ff!important;
}
.v-main{
  background: #f7f7f7;
  
}
.v-chip{
  justify-content: flex-end!important;
}

.v-btn {
  font-weight:100;
border-radius: 100px;
    text-transform: none;
font-style: normal;
font-weight: 400;
letter-spacing: 0.0302857143em;
}
.v-btn--size-x-large{
  font-size: 1rem;
}
a {
  text-decoration: none;
}
select, input {
  background-color:'#4863ff1b';
  border: none;
}
.v-field--active  {
background-color:#edeff2;
border: none;

}
.v-textarea {
  font-weight: 300!important;
}

.v-field--focused div div:focus {
   outline:none;
}
.v-chip {
    border-radius: 8px;
}
.text-large{

font-size: 25px;
font-weight: 500;
line-height: 40px;
}
.text-editor-card{

              position:fixed!important;
              height: calc(100vh - 48px) !important;
              top: 48px;
              bottom: 0px;
              right:0;
              width:30%;
}
.text-editor-card-onsearch{
                position:fixed!important;
              height: calc(100vh - 48px) !important;
              top: 48px;
              bottom: 0px;
              right:256px;
              width:calc(30% - (150px/2));
}

.text-small{
  //styleName: Material 3.0/Headline/Small;

font-size: 24px;
font-weight: 400;
line-height: 32px;
letter-spacing: 0em;
text-align: center;

}
/*.v-field__input{
  border:none;
  max-height:40px;
}*/
/*.v-field__append-inner{
align-items: flex-start!important;*
}*/
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #4863FF;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
html{
  overflow-y: scroll!important;
  font-weight:300!important;
  font-family: Inter, sans-serif !important;
}


.v-file-input .v-label.v-field-label{
  margin-top: -9px!important;
  
}
.v-label v-field-label{
font-size: 13px;
  font-family: Inter, sans-serif !important
}
.v-field__field{
  font-size: 14px!important;  
  /*font-weight: 400!important;*/
}
.v-expansion-panel-text__wrapper {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
 div::-webkit-scrollbar {
  width: 5px;
}

div::-webkit-scrollbar-track {
  /*box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  background-color: #f9f9fd;*/
  border-radius: 10px;
  margin-bottom: 8px;
  margin-top: 8px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #a3a3a3;
}

.lName {
  margin: 0; 
    -webkit-line-clamp: 2; 
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    overflow: hidden;
}
.v-card-text{
  font-size: 0.812rem;
}
.v-card-title{
  font-size: 18px;
}
.v-card-title{
  font-size: 18px;
}
.v-list-item-title{
font-size: 15px;
}
.v-list-item{
  padding: 4px 8px;
}
 
div{
   font-size: 0.814rem;
}
p{
   font-size: 0.814rem;
}
.s-13{
  font-size: 13px;
  font-family: Inter, sans-serif !important;
}
.v-overlay{
 max-width:none!important; 
 width:100%!important; 
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.s-15{
  font-size: 15px;
  font-family: Inter, sans-serif !important;
}
.v-list-item--variant-text .v-list-item__overlay {
    background: #000000;
    
}
.v-tooltip .v-overlay__content {
  background: #000000ce!important;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.v-select{
   --v-input-control-height: 8px!important;
   --v-input-padding-top: 3px!important;
    --v-input-padding-bottom: 5px!important;
}
.v-input--density-default .v-field--variant-outlined {
 --v-field-padding-bottom: 3px!important;
}
.v-field__prepend-inner{
      display: flex;
      align-items: center;
      padding-top:0!important;
}
.v-field__append-inner {
   display: flex;
   align-items: center;

}
.v-dialog > .v-overlay__content {

  align-items: center;
}
.main-font {
font-family: Inter, sans-serif !important;
}

</style>
